import React, { useState, useEffect } from "react";

import { ReactComponent as SvgNext } from "../../assets/images/ui/arrow-next.svg";
import { ReactComponent as SvgPrev } from "../../assets/images/ui/arrow-prev.svg";

import "./UiPaginator.scss";

function UiPaginator(props) {
  const [pagesArr, setPagesArr] = useState([]);
  const [active, setActive] = useState(0);

  const pressPrev = () => { };
  const pressNext = () => { };
  const pressButton = () => { };

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < Math.round(props.lastPage); i++) {
      arr.push(i);
    }
    setPagesArr(arr);
  }, [props.lastPage]);

  let pages = pagesArr.map((item, index) => {
    if (index >= props.currentPage - 1 && index < props.currentPage + 3)
      return (
        <button
          key={index}
          className={
            "paginator-num" + (props.currentPage == index ? " active" : "")
          }
          onClick={() => {
            props.onChange(index);
          }}
        >
          {item}
        </button>
      );
  });

  let pages9 = pagesArr.map((item, index) => {
    if (index < 10)
      return (
        <button
          key={index}
          className={
            "paginator-num" + (props.currentPage == index ? " active" : "")
          }
          onClick={() => {
            props.onChange(index);
          }}
        >
          {item}
        </button>
      );
  });

  return (
    <div className="paginator">
      <div className="paginator-inner">
        <div className="paginator-button-wrap prev">
          <button
            className={
              "paginator-button prev" +
              (props.currentPage == 1 ? " disabled" : "")
            }
            onClick={() => {
              if(props.currentPage >= 1) props.onChange(props.currentPage - 1);
            }}
          >
            <SvgPrev fill="#000" className="paginator-button-icon" />
          </button>
        </div>
        {props.lastPage < 10 ?
          <div>
            <div className="paginator-num-buttons">
              {pages9}
            </div>
          </div>
          :
          <div>
            {props.currentPage <= 3 ? (
              <div className="paginator-num-buttons">
                <button
                  className={
                    "paginator-num" + (props.currentPage == 0 ? " active" : "")
                  }
                  onClick={() => {
                    props.onChange(pagesArr[0]);
                  }}
                >
                  {pagesArr[0] + 1}
                </button>
                <button
                  className={
                    "paginator-num" + (props.currentPage == 1 ? " active" : "")
                  }
                  onClick={() => {
                    props.onChange(pagesArr[1]);
                  }}
                >
                  {pagesArr[1] + 1}
                </button>
                <button
                  className={
                    "paginator-num" + (props.currentPage == 2 ? " active" : "")
                  }
                  onClick={() => {
                    props.onChange(pagesArr[2]);
                  }}
                >
                  {pagesArr[2] + 1}
                </button>
                <button
                  className={
                    "paginator-num" + (props.currentPage == 3 ? " active" : "")
                  }
                  onClick={() => {
                    props.onChange(pagesArr[3]);
                  }}
                >
                  {pagesArr[3] + 1}
                </button>

                <div className="paginator-spots">...</div>
                <button
                  className="paginator-num"
                  onClick={() => {
                    props.onChange(pagesArr[pagesArr.length - 3]);
                  }}
                >
                  {pagesArr[pagesArr.length - 3] + 1}
                </button>
                <button
                  className="paginator-num"
                  onClick={() => {
                    props.onChange(pagesArr[pagesArr.length - 2]);
                  }}
                >
                  {pagesArr[pagesArr.length - 2] + 1}
                </button>
                <button
                  className="paginator-num"
                  onClick={() => {
                    props.onChange(pagesArr[pagesArr.length - 1]);
                  }}
                >
                  {pagesArr[pagesArr.length - 1] + 1}
                </button>
              </div>
            ) : null}

            {props.currentPage > 3 && props.currentPage < pagesArr.length - 4 ? (
              <div className="paginator-num-buttons">
                <button
                  className={
                    "paginator-num" + (props.currentPage == 1 ? " active" : "")
                  }
                  onClick={() => {
                    props.onChange(pagesArr[0]);
                  }}
                >
                  {pagesArr[0]}
                </button>
                <div className="paginator-spots">...</div>
                {pages}
                <div className="paginator-spots">...</div>
                <button
                  className="paginator-num"
                  onClick={() => {
                    props.onChange(pagesArr[pagesArr.length - 1]);
                  }}
                >
                  {pagesArr[pagesArr.length - 1]}
                </button>
              </div>
            ) : null}

            {props.currentPage > pagesArr.length - 3 ? (
              <div className="paginator-num-buttons">
                <button
                  className={
                    "paginator-num" + (props.currentPage == 1 ? " active" : "")
                  }
                  onClick={() => {
                    props.onChange(pagesArr[0]);
                  }}
                >
                  {pagesArr[0] + 1}
                </button>
                <div className="paginator-spots">...</div>

                <button
                  className={
                    "paginator-num" +
                    (props.currentPage == pagesArr[pagesArr.length - 4]
                      ? " active"
                      : "")
                  }
                  onClick={() => {
                    props.onChange(pagesArr[pagesArr.length - 4]);
                  }}
                >
                  {pagesArr[pagesArr.length - 4] + 1}
                </button>
                <button
                  className={
                    "paginator-num" +
                    (props.currentPage == pagesArr[pagesArr.length - 3]
                      ? " active"
                      : "")
                  }
                  onClick={() => {
                    props.onChange(pagesArr[pagesArr.length - 3]);
                  }}
                >
                  {pagesArr[pagesArr.length - 3] + 1}
                </button>
                <button
                  className={
                    "paginator-num" +
                    (props.currentPage == pagesArr[pagesArr.length - 2]
                      ? " active"
                      : "")
                  }
                  onClick={() => {
                    props.onChange(pagesArr[pagesArr.length - 2]);
                  }}
                >
                  {pagesArr[pagesArr.length - 2] + 1}
                </button>
                <button
                  className={
                    "paginator-num" +
                    (props.currentPage == pagesArr[pagesArr.length - 1]
                      ? " active"
                      : "")
                  }
                  onClick={() => {
                    props.onChange(pagesArr[pagesArr.length - 1]);
                  }}
                >
                  {pagesArr[pagesArr.length - 1] + 1}
                </button>
              </div>
            ) : null}
          </div>
        }


        <div className="paginator-button-wrap next">
          <button
            className={
              "paginator-button next" +
              (props.currentPage >= pagesArr[pagesArr.length - 1]
                ? " disabled"
                : "")
            }
            onClick={() => {
              if(props.currentPage < pagesArr[pagesArr.length - 1]) props.onChange(props.currentPage + 1);
            }}
          >
            <SvgNext fill="#000" className="paginator-button-icon" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default UiPaginator;
